/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter,
    GeneralStatus,
    PlanTypeDto,
    PlanTypeDtoListResponseModel,
    PlanTypeDtoModelResponse,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class PlanTypes<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags PlanTypes
     * @name PostPlanTypes
     * @request POST:/PlanTypes/Add
     */
    postPost = (data: PlanTypeDto, params: RequestParams = {}) => this.postPlanTypes(data, params)
    /**
     * No description
     *
     * @tags PlanTypes
     * @name PostPlanTypes
     * @request POST:/PlanTypes/Add
     */
    postPlanTypes = (data: PlanTypeDto, params: RequestParams = {}) =>
        this.request<PlanTypeDtoModelResponse, any>({
            path: `/PlanTypes/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PlanTypes
     * @name LoadList
     * @request GET:/PlanTypes/Load
     */
    loadList = (
        query?: {
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<PlanTypeDtoListResponseModel, any>({
            path: `/PlanTypes/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PlanTypes
     * @name GetByIdList
     * @request GET:/PlanTypes/GetById
     */
    getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PlanTypeDtoModelResponse, any>({
            path: `/PlanTypes/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PlanTypes
     * @name DeleteDelete
     * @request DELETE:/PlanTypes/Delete
     */
    deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PlanTypeDtoModelResponse, any>({
            path: `/PlanTypes/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PlanTypes
     * @name UpdateCreate
     * @request POST:/PlanTypes/Update
     */
    updateCreate = (data: PlanTypeDto, params: RequestParams = {}) =>
        this.request<PlanTypeDtoModelResponse, any>({
            path: `/PlanTypes/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
